.form-btn {
	border: 1px solid transparent;
	outline: none;
	user-select: none;
	background: #6bce66;
	border-radius: 5px;
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;
	margin-top: 35px;
	width: 350px;
	height: 50px;
	cursor: pointer;
}
