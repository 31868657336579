.login-view {
  width: 100vw;
  height: 100vh;
  display: flex;

  &-bg {
    width: 60%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-container {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      margin-bottom: 50px;
    }
  }

  &-logo {
    &__title {
      color: #4da2d9;
      text-transform: uppercase;
      margin: 0;
      font-weight: 500;
      font-size: 50px;
    }

    &__subtitle {
      font-family: Roboto;
      margin: 0 0 50px;
      font-size: 12px;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;

    &__subtitle {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #2D3748;
      margin: 0 0 2px;
    }

    &__title {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 35px;
      color: #1A202C;
      margin: 0 0 41px;
    }

    &__input {
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .login-view-bg {
    display: none;
  }
  .login-view-container {
    width: 100%;
  }
}